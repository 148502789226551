<template>
  <div class="ss-tonnage">
    <div v-if="!isMobile" class="search-ton">
      <div class="ton-top">
        <el-card shadow="never" class="box-card">
          <el-form ref="searchTonform" :model="searchTonform" label-width="80px" size="small" :rules="rules">
            <div class="form-left">
              <el-row>
                <el-col :span="10">
                  <el-form-item :label="$t('message.search.vesselname')" label-width="90px" prop="vessel_name">
                    <el-autocomplete v-model="searchTonform.shipVesselName"
                                     style="width: 80%"
                                     :placeholder="$t('message.search.vesselname')"
                                     :fetch-suggestions="querySearchVessel"
                                     :trigger-on-focus="false"
                                     @select="selectvessel"
                                     @input="vesselname($event)"
                                     @change="reselectvessel"
                    >
                      <template #default="{ item }">
                        <el-row>
                          <el-col :span="15"><span class="value">{{ item.value }}</span></el-col>
                          <el-col :span="9"> <span class="link" style="font-size:smaller">{{ item.dwt }}/{{ item.built }}</span></el-col>
                        </el-row>
                      </template>
                    </el-autocomplete>
                  </el-form-item>
                </el-col>
                <el-col :span="2"></el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('message.search.dwt')" >
                    <el-col :span="6">
                      <el-form-item prop="dwtDown"  >
                        <el-input v-model.number="searchTonform.dwtDown" type='number' @change="dwtDownchange" :placeholder="$t('message.search.min')"  :min="0" oninput="if(value<0)value=0" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="1">--</el-col>
                    <el-col :span="6">
                      <el-form-item prop="dwtUp" >
                        <el-input v-model.number="searchTonform.dwtUp" type='number' @change="dwtUpchange"  :placeholder="$t('message.search.max')"   :min="0" oninput="if(value<0)value=0" />
                      </el-form-item>
                    </el-col>
                    <el-col  :span="1"></el-col>
                    <el-col :span="8">
                      <el-form-item prop="dwt_type">
                        <el-select v-model="searchTonform.dwt_type"
                                   :placeholder="$t('message.search.dwttype')"
                                   @change="typechange"
                                   prop="dwt_type">
                          <el-option
                              v-for="item in options"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item :label="$t('message.search.opendate')" label-width="90px" >
                    <el-col :span="11">
                      <el-form-item prop="openStartDate">
                        <el-date-picker type="date" :placeholder="$t('message.search.startdate')"  v-model="searchTonform.openStartDate"  style="width: 100%;">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col class="line" :span="2">--</el-col>
                    <el-col :span="11">
                      <el-form-item prop="openEndDate">
                        <el-date-picker type="date" :placeholder="$t('message.search.enddate')" v-model="searchTonform.openEndDate" style="width: 100%;"></el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="1"></el-col> -->
                <el-col :span="12">
                  <el-form-item :label="$t('message.search.openarea')">
                    <el-col :span="11">
                      <el-form-item prop="openseaarea">
                        <el-autocomplete v-model="openseaarea"
                                         :placeholder="$t('message.search.openarea')"
                                         style="width: 100%;"
                                         :fetch-suggestions="querySearchOpenSea"
                                         :trigger-on-focus="false"
                                         @select="selectopensea"
                                         @input="opensea($event)"
                                         @change="changeopenSeaareaName"
                        >
                        </el-autocomplete>
                      </el-form-item>
                    </el-col>
                    <el-col class="line" :span="2">--</el-col>
                    <el-col :span="11">
                      <el-form-item prop="open_port">
                        <el-autocomplete v-model="open_port"
                                         :placeholder="$t('message.search.openport')"
                                         style="width: 100%;"
                                         :fetch-suggestions="querySearchOpenPort"
                                         :trigger-on-focus="true"
                                         @select="selectopenport"
                                         @input="openport($event)"
                                         @change="reselectport"
                        >
                        </el-autocomplete>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10">
                  <el-form-item :label="$t('message.search.vesselage')+ '<' " label-width="90px" prop="years">
                    <el-select v-model="searchTonform.built" :placeholder="$t('message.search.vesselage')">
                      <el-option
                          v-for="item in options2"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                    <el-button type="info" plain>{{$t('message.years')}}</el-button>
                  </el-form-item>
                </el-col>
                <el-col :span="2"></el-col>
                <el-col :span="10">
                  <el-form-item>
                    <el-button class="search_btn"  type="primary" :disabled="searchdisabled" @click="searchtonnage">{{$t('message.sidebar.search')}}</el-button>
                    <el-button @click="resetForm('searchTonform')">{{$t('message.reset')}}</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div class="form-right">
              <!-- <el-button class="search_btn"  type="primary" @click="onSubmit">搜索</el-button>
              <el-button>重置</el-button> -->
            </div>
          </el-form>
        </el-card>
      </div>
      <el-tabs class="result_list" v-model="activeName"  @tab-click="handleClick">
        <!--卡片显示信息-->
        <el-tab-pane :label="$t('message.card')" name="card">
          <div class="list-area">
            <div v-for="(item) of tableData" :key="item.id"  class='list-item'>
              <list-card  :flag="1" :dataObj="item" :collect="searchtonnage" @searchtonnage="searchtonnage"></list-card>
            </div>
          </div>
        </el-tab-pane>
        <!--列表显示信息-->
        <!--tableData[index].id-->
        <el-tab-pane :label="$t('message.list')" name="table">
          <el-table
              #default="scope"
              :data="tableData" :border="false"
              style="width: 100%"
              @row-click="goDetails"
              :header-cell-style="{background:'#fff',color:'#515a6e','text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              :show-overflow-tooltip="true">
            <el-table-column :label="$t('message.collect')">
              <template #default="scope">
                <div class="collect">
                  <star-box :Id="scope.row.id" :collect="searchtonnage" :selectId="scope.row.selectId"  :tag="scope.row.tag" :fontSize="starFontSize"></star-box>
                </div>
              </template>
            </el-table-column>
            <el-table-columnC
                prop="comment.shipVesselCname"
                :label="$t('message.search.vesselname')"
                min-width="60"
            >
            </el-table-columnC>
            <el-table-column
                prop="comment.dwt"
                :label="$t('message.search.dwt')"
                min-width="60"
            >
            </el-table-column>
            <el-table-column
                prop="comment.built"
                :label="$t('message.tonnage.built')"
                min-width="80"
            >
            </el-table-column>
            <el-table-column
                prop="openStartDate"
                :label="$t('message.tonnage.openDate')"
                min-width="160"
            >
              <template #default="scope">
                <i class="el-icon-time"></i>
                <span style="margin-left: 5px">{{ scope.row.comment.openStartDate}} ~ {{scope.row.comment.openEndDate}}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="comment.openSeaareaCname"
                :label="$t('message.tonnage.openArea')"
                min-width="100"
            >
              <template  #default="scope">
                <span>{{lang == 'en' ? scope.row.comment.openSeaareaEname: scope.row.comment.openSeaareaCname}},{{lang == 'en' ? scope.row.comment.openPortEname: scope.row.comment.openPortCname}}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="senderTime"
                :label="$t('message.addtonnage.sendtime')"
                min-width="100"
            >
              <template #default="scope">
                <i class="el-icon-time"></i>
                <span style="margin-left: 5px">{{ scope.row.senderTime}}</span>
              </template>
            </el-table-column>
            <el-table-column
                :label="$t('message.handle')"
                min-width="200">
              <template #default="scope">
                <div class="actions">
                  <div v-if="scope.row.come == 1" class="actions-box">
                    <el-button
                        size="mini"
                        :disabled="editdisabled"
                        @click.stop="go_edit(scope.row.id)">{{$t('message.edit')}}
                    </el-button>
                    <el-button
                        size="mini" type="danger"
                        :disabled="deletedisabled"
                        @click.stop="open_Del(scope.row.id)">{{$t('message.delete')}}
                    </el-button>
                    <el-button
                        size="mini" type="success"
                        :disabled="matchdisabled"
                        @click.stop="go_match(scope.row)" plain>{{$t('message.matching')}}
                    </el-button>
                    <el-button
                        size="mini" type="primary"
                        @click="handleDelete(scope.$index, scope.row)">{{$t('message.original')}}
                    </el-button>
                  </div>
                  <div v-if="scope.row.come == 2" class="actions-box">
                    <el-button
                        size="mini" type="success"
                        :disabled="matchdisabled"
                        @click.stop="go_match(scope.row)" plain
                    >{{$t('message.matching')}}
                    </el-button>
                  </div>
                  <div class="collect">
                    <star-box :fontSize="starFontSize"></star-box>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div  class="list-footer">
        <!--<el-pagination-->
        <!--@size-change="handleSizeChange"-->
        <!--@current-change="handleCurrentChange"-->
        <!--v-model:currentPage="currentPage1"-->
        <!--hide-on-single-page="true"-->
        <!--:page-size="5"-->
        <!--layout="slot, prev, pager, next"-->
        <!--:total="100">-->
        <!--<span id="tiao">共 100 条</span>-->
        <!--</el-pagination>-->

        <!--公司代码-->
        <el-pagination
            small
            :prev-text="$t('message.previous')"
            :next-text="$t('message.next')"
            layout="slot,prev, pager, next"
            background
            v-model:currentPage="currentPage1"
            :total="pagination.total"
            :page-size="pagination.pagesize"
            @current-change="currentChange"
        />
      </div>
      <el-dialog
          :title="$t('message.email.modifyinformation')"
          v-model="deit_dialog"
          width="30%"
          :before-close="handleClose"
      >
        <span>{{$t('message.email.sentence1')}}</span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="deit_dialog = false">{{$t('message.email.cancel')}}</el-button>
            <el-button type="primary" @click="go_edit(editObj.id)">{{$t('message.email.ok')}}</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
          :title="$t('message.email.matchinformation')"
          v-model="match_dialog"
          width="30%"
          :before-close="handleClose"
      >
        <span>{{$t('message.email.sentence2')}}</span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="match_dialog = false">{{$t('message.email.cancel')}}</el-button>
            <el-button type="primary" @click="go_match(editObj.id)">{{$t('message.email.ok')}}</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
          :title="$t('message.email.deleteinformation')"
          v-model="del_dialog"
          width="30%"
          :before-close="handleClose"
      >
        <span>{{$t('message.email.sentence3')}}</span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="del_dialog = false">{{$t('message.email.cancel')}}</el-button>
            <el-button type="primary" @click="go_del(editObj.id)">{{$t('message.email.ok')}}</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <div v-if="isMobile" class="search-ton-mobile">
      <div class="ton-top">
        <el-card class="box-card">
          <el-form ref="searchTonform" :model="searchTonform" label-width="80px" size="small">
            <el-form-item id="vessel_name" label="船舶名称">
              <el-autocomplete v-model="vessel_name" style="width: 80%;" placeholder="请输入船舶名称">
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="载重吨">
              <el-col :span="5">
                <el-input v-model="searchTonform.dwt_min"></el-input>
              </el-col>
              <el-col :span="1">-</el-col>
              <el-col :span="5">
                <el-input v-model="searchTonform.dwt_max"></el-input>
              </el-col>
              <el-col id="col_2" :span="2">----</el-col>
              <el-col :span="8">
                <el-select v-model="searchTonform.dwt_type" placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item label="空船时间">
              <el-col :span="11">
                <el-date-picker type="date" placeholder="开始日期" v-model="searchTonform.open_start_date" style="width: 100%;">
                </el-date-picker>
              </el-col>
              <el-col class="line" :span="2">--</el-col>
              <el-col :span="11">
                <el-date-picker placeholder="结束日期" v-model="searchTonform.open_end_date" style="width: 100%;"></el-date-picker>
              </el-col>
            </el-form-item>
            <el-form-item label="空船海域">
              <el-col :span="11">
                <el-autocomplete v-model="searchTonform.open_seaarea" placeholder="空船海域" style="width: 100%;">
                </el-autocomplete>
              </el-col>
              <el-col class="line" :span="2">--</el-col>
              <el-col :span="11">
                <el-autocomplete v-model="open_port" placeholder="空船港口" style="width: 100%;">
                </el-autocomplete>
              </el-col>
            </el-form-item>
            <el-form-item label="船龄">
              <el-select v-model="years" placeholder="请选择">
                <el-option
                    v-for="item in options2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
              <span id="ship_years">Years</span>
            </el-form-item>
            <el-form-item id="btn-box" size="medium">
              <el-button class="search_btn"  type="primary" :disabled="disabled" @click="onSubmit">搜索</el-button>
              <el-button>重置</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
      <h2>搜索结果</h2>
      <div v-if="isMobile" class="list-area-mobile">
        <div v-for="(item,index) of 5" :key="index"  class='list-item' @click="goDetail()">
          <list-card></list-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import ListCard from '../../components/list-card/ListCard.vue';
  import StarBox from '../../components/star-box/StarBox'
  import {searchcollecttonnage , deleteTonnage} from '../../api/tonnage'
  import {getseaarea} from "../../api/associativeinput";
  export default {
    inject:['reload'],
    components:{ListCard,StarBox},
    data() {
      const validatedwtDown = (rule, value, callback) => {
        if(this.searchTonform.dwtUp!==''){
          if (parseInt(this.searchTonform.dwtDown)>parseInt(this.searchTonform.dwtUp)||
              parseFloat(this.searchTonform.dwtDown)>parseFloat(this.searchTonform.dwtUp)){
            callback(new Error(window.vm.$t('message.minmessage')))
          }
          else {
            callback()
          }}
      };
      const validatedwtUp = (rule, value, callback) => {
        if (parseInt(this.searchTonform.dwtDown)>parseInt(this.searchTonform.dwtUp)||
            parseFloat(this.searchTonform.dwtDown)>parseFloat(this.searchTonform.dwtUp)){
          callback(new Error(window.vm.$t('message.maxmessage')))
        }
        else {
          callback()
        }
      };
      const validateOpenStart = (rule, value, callback) => {
        if (this.searchTonform.openEndDate !=='' &&this.searchTonform.openStartDate > this.searchTonform.openEndDate) {
          callback(new Error(window.vm.$t('message.startdatevalide')))
        } else {
          callback()
        }
      };
      const validateopenEndDate = (rule, value, callback) => {
        if(this.searchTonform.openEndDate!==''){
          if(this.searchTonform.openStartDate>this.searchTonform.openEndDate){
            callback(new Error(window.vm.$t('message.enddatevalide')))
          }
          else{
            callback()
          }}
      };
      return {
        vessel_name: '',//v-model
        open_seaarea: '',
        open_port: '',
        openseaarea:'',
        years: '',
        searchdisabled:'',//按钮权限
        buttonpermission:[],//按钮权限数组
        detaildisabled:'',
        editdisabled:'',
        deletedisabled:'',
        matchdisabled:'',
        searchTonform: {
          dwtDown: '',
          dwtUp: '',
          openStartDate: '',
          openEndDate: '',
          built: '',
          sendTime: '',
          languageCode: '',
          openPortId: '',
          openPortCname: '',
          openPortEname: '',
          openSeaareaId: '',
          openSeaareaCname: '',
          openSeaareaEname: '',
          shipId: '',
          shipVesselCname: '',
          shipVesselEname: '',
          shipVesselName: '',
        },
        pagination: {
          current: 0,
          pagesize: 6,
          total: 0
        },
        options: [{
          value: '0',
          label: 'Other'
        }, {
          value: '1',
          label: 'Small Handy'
        }, {
          value: '2',
          label: 'Handysize'
        }, {
          value: '3',
          label: 'Large Handy'
        }, {
          value: '4',
          label: 'Handy Max'
        },
          {
            value: '5',
            label: 'Super Max'
          }, {
            value: '6',
            label: 'Ultra Max'
          },
          {
            value: '7',
            label: 'Pana Max'
          },
          {
            value: '8',
            label: 'Kamsar Max'
          },
          {
            value: '9',
            label: 'Post Panamax'
          },
          {
            value: '10',
            label: 'Afra Max'
          },
          {
            value: '11',
            label: 'Capesize'
          },
          {
            value: '12',
            label: 'VLCC'
          },
          {
            value: '13',
            label: 'ULCC'
          }
        ],
        options2: [
          {
            value: 5,
            label: '5'
          },
          {
            value: 10,
            label: '10'
          },
          {
            value: 15,
            label: '15'
          },
          {
            value: 20,
            label: '20'
          },
          {
            value: 25,
            label: '25'
          },
          {
            value: 30,
            label: '30'
          },
          {
            value: 35,
            label: '35'
          },
          {
            value: 40,
            label: '40'
          },
          {
            value: 50,
            label: '50'
          },
          {
            value: 60,
            label: '60'
          },
          {
            value: 70,
            label: '70'
          },
          {
            value: 80,
            label: '80'
          },
          {
            value: 90,
            label: '90'
          },
          {
            value: 100,
            label: '100'
          },
        ],
        activeName: 'card',
        tableData: [],
        starFontSize: '28px',
        editObj: {}, //点击编辑按钮，传入的编辑对象
        matchObj: {},
        delObj: {},
        deit_dialog: false,
        match_dialog: false,
        del_dialog: false,
        currentPage1: 1,
        intid: '',
        rules: {
          dwtDown: [{ validator: validatedwtDown, type: 'number', trigger: ['blur','change']  },],
          dwtUp: [{ validator: validatedwtUp, type: 'number', trigger: ['blur','change']  },],
          openEndDate: [{ validator: validateopenEndDate, trigger: ['blur','change']  },],
          openStartDate: [{ validator: validateOpenStart,  trigger: ['blur','change']  },],
        }
      };
    },
    computed:{
      ...mapGetters(['lang','permission','isMobile','portnamegroup','seanamegroup','vesselnamegroup'])
    },

    methods:{
      goDetails(row){
        this.intid= row.id
        this.$router.push(`/tonnage/addtonnage/${2}/${this.intid}`)
      },
      goDetail(id){
        this.$router.push(`/tonnage/addtonnage/${2}/${id}`)
      },
      go_edit(id){
        this.$router.push(`/tonnage/addtonnage/${1}/${id}`)
      },
      go_match(obj){
        this.$store.commit("metadata/SET_Tonnageinfo", obj);
        this.$router.push(`/tonnage/tonmatching/${obj.id}`)
      },
      go_del(){
        var deleteId = [this.delObj]
        deleteTonnage(deleteId).then(response =>{
          if(response.error_code==200){
            this.$message.success(window.vm.$t('message.tonnage.deletedinfo'))
            this.del_dialog=false
            this.searchtonnage()
          }
        })
      },
      open_Match(matchObj){
        this.matchObj = matchObj
        this.match_dialog = true
      },
      open_Edit(editObj) {
        this.editObj = editObj
        this.deit_dialog = true
      },
      open_Del(delObj){
        this.delObj = delObj
        this.del_dialog = true
      },
      //检索船舶动态信息
      searchtonnage(){
        if(this.lang == 'en'){
          this.searchTonform.languageCode = 2
        }else{
          this.searchTonform.languageCode = 1
        }
        const params = {
          query: this.searchTonform,
          pagination: this.pagination

        }
        searchcollecttonnage(params).then(response =>{
          if(response.error_code ==200 ){
            this.tableData=response.datas;
            this.pagination.total = response.pagination.total
          }
        })
      },
      typechange(obj){
        if ( this.options[obj].label == 'Other'){
         // console.log("我是Other")
          this.searchTonform.dwtDown=null
          this.searchTonform.dwtUp= null
        }
        else if ( this.options[obj].label == 'Small Handy'){
        //  console.log("我在这里")
          this.searchTonform.dwtDown= 0
          this.searchTonform.dwtUp= 10000
        }
        else if ( this.options[obj].label == 'Handysize'){
        //  console.log("我在这里")
          this.searchTonform.dwtDown= 10000
          this.searchTonform.dwtUp= 40000
        }
        else if(this.options[obj].label == 'Large Handy'){
          this.searchTonform.dwtDown= 30000
          this.searchTonform.dwtUp= 39999
        }
        else if(this.options[obj].label == 'Handy Max'){
          this.searchTonform.dwtDown= 40000
          this.searchTonform.dwtUp= 50000
        }else if(this.options[obj].label == 'Super Max'){
          this.searchTonform.dwtDown= 50000
          this.searchTonform.dwtUp= 59999
        }
        else if(this.options[obj].label == 'Ultra Max'){
          this.searchTonform.dwtDown= 60000
          this.searchTonform.dwtUp= 64999
        }
        else if(this.options[obj].label == 'Pana Max'){
          this.searchTonform.dwtDown= 65000
          this.searchTonform.dwtUp= 79999
        }
        else if(this.options[obj].label == 'Kamsar Max'){
          this.searchTonform.dwtDown= 80000
          this.searchTonform.dwtUp= 89999
        }
        else if(this.options[obj].label == 'Post Panamax'){
          this.searchTonform.dwtDown= 90000
          this.searchTonform.dwtUp= 99999
        }
        else if(this.options[obj].label == 'Afra Max'){
          this.searchTonform.dwtDown= 80000
          this.searchTonform.dwtUp= 110000
        }
        else if(this.options[obj].label == 'Capesize'){
          this.searchTonform.dwtDown= 100000
          this.searchTonform.dwtUp= 450000
        }
        else if(this.options[obj].label == 'VLCC'){
          this.searchTonform.dwtDown= 150000
          this.searchTonform.dwtUp= 320000
        }else if(this.options[obj].label == 'ULCC'){
          this.searchTonform.dwtDown= 320000
          this.searchTonform.dwtUp= 550000
        }
      },
      dwtDownchange(obj){
        if(obj == 0 && this.searchTonform.dwtUp== 10000){
          this.searchTonform.dwt_type = 'Small Handy'
        }else if (obj== 10000 && this.searchTonform.dwtUp== 40000){
          this.searchTonform.dwt_type = 'Handysize'
        } else if( obj== 30000 && this.searchTonform.dwtUp== 39999){
          this.searchTonform.dwt_type = 'Large Handy'
        } else if(obj== 40000 && this.searchTonform.dwtUp== 50000){
          this.searchTonform.dwt_type = 'Handy Max'
        } else if(obj== 50000 && this.searchTonform.dwtUp== 59999){
          this.searchTonform.dwt_type = 'Super Max'
        } else if(obj== 60000 && this.searchTonform.dwtUp== 64999){
          this.searchTonform.dwt_type = 'Ultra Max'
        } else if(obj== 65000 && this.searchTonform.dwtUp== 79999){
          this.searchTonform.dwt_type = 'Pana Max'
        } else if(obj== 80000&& this.searchTonform.dwtUp== 89999){
          this.searchTonform.dwt_type = 'Kamsar Max'
        } else if(obj== 90000&& this.searchTonform.dwtUp== 99999){
          this.searchTonform.dwt_type = 'Post Panamax'
        } else if( obj== 80000&& this.searchTonform.dwtUp== 110000){
          this.searchTonform.dwt_type = 'Afra Max'
        } else if(obj== 100000&& this.searchTonform.dwtUp== 450000){
          this.searchTonform.dwt_type = 'Capesize'
        }else if(obj== 150000&& this.searchTonform.dwtUp== 320000){
          this.searchTonform.dwt_type = 'VLCC'
        }else if( obj== 320000&& this.searchTonform.dwtUp== 550000){
          this.searchTonform.dwt_type = 'ULCC'
        }else{
          this.searchTonform.dwtDown = obj
          this.searchTonform.dwt_type = 'other'
        }
      },
      dwtUpchange(obj){
        if(obj == 10000 && this.searchTonform.dwtDown== 0){
          this.searchTonform.dwt_type = 'Small Handy'
        }else if (obj== 40000 && this.searchTonform.dwtDown== 10000){
          this.searchTonform.dwt_type = 'Handysize'
        } else if( obj== 39999 && this.searchTonform.dwtDown== 30000){
          this.searchTonform.dwt_type = 'Large Handy'
        } else if(obj== 50000 && this.searchTonform.dwtDown== 40000){
          this.searchTonform.dwt_type = 'Handy Max'
        } else if(obj== 59999 && this.searchTonform.dwtDown== 50000){
          this.searchTonform.dwt_type = 'Super Max'
        } else if(obj== 64999 && this.searchTonform.dwtDown== 60000){
          this.searchTonform.dwt_type = 'Ultra Max'
        } else if(obj== 79999 && this.searchTonform.dwtDown== 65000){
          this.searchTonform.dwt_type = 'Pana Max'
        } else if(obj== 89999&& this.searchTonform.dwtDown== 80000){
          this.searchTonform.dwt_type = 'Kamsar Max'
        } else if(obj== 99999&& this.searchTonform.dwtDown== 90000){
          this.searchTonform.dwt_type = 'Post Panamax'
        } else if( obj== 110000&& this.searchTonform.dwtDown== 80000){
          this.searchTonform.dwt_type = 'Afra Max'
        } else if(obj== 450000&& this.searchTonform.dwtDown== 100000){
          this.searchTonform.dwt_type = 'Capesize'
        }else if(obj== 320000&& this.searchTonform.dwtDown== 150000){
          this.searchTonform.dwt_type = 'VLCC'
        }else if( obj== 550000&& this.searchTonform.dwtDown== 320000){
          this.searchTonform.dwt_type = 'ULCC'
        }else{
          this.searchTonform.dwtUp = obj
          this.searchTonform.dwt_type = 'other'
        }
      },
      //船名联想输入的查询
      querySearchVessel(queryString, callback){
        this.$store.commit('metadata/querySearchVessel',queryString)
        callback(this.vesselnamegroup)
      },
      //开放海域联想输入
      querySearchOpenSea(queryString, callback){
        this.$store.commit('metadata/querySearchSea',queryString)
        callback(this.seanamegroup)
      },
      //开放港口联想输入
      querySearchOpenPort(queryString, callback){
        this.$store.commit('metadata/querySearchPort', {"queryString":queryString,"seaAreaId":this.searchTonform.openSeaareaI});
        callback(this.portnamegroup)
      },
      //联想输入select
      selectvessel(item){
        console.log(item)
          this.searchTonform.shipId = item.id
          this.searchTonform.shipVesselName = item.value
      },
      selectopensea(item) {
        if (this.lang == 'en') {
          this.searchTonform.openSeaareaId = item.id
          this.searchTonform.openSeaareaCname = item.cname
          this.searchTonform.openSeaareaEname = item.value
        } else {
          this.searchTonform.openSeaareaId = item.id
          this.searchTonform.openSeaareaCname = item.value
          this.searchTonform.openSeaareaEname = item.ename
        }
      },
      selectopenport(item){
        if(this.lang == 'en'){
          this.searchTonform.openPortId = item.id
          this.searchTonform.openPortCname = item.cname
          this.searchTonform.openPortEname = item.value
        }else {
          this.searchTonform.openPortId = item.id
          this.searchTonform.openPortCname = item.value
          this.searchTonform.openPortEname = item.ename
        }
        getseaarea(item.seaAreaId).then(res =>{
          this.searchTonform.openSeaareaId = res.data.id
          this.searchTonform.openSeaareaEname = res.data.ename
          this.searchTonform.openSeaareaCname = res.data.cname
          if (this.lang == 'en') {
            this.searchTonform.openSeaareaCname = res.data.ename
          } else {
            this.searchTonform.openSeaareaCname = res.data.cname
          }
        })
      },
      reselectvessel(){
        if(this.vesselnamegroup.length != 0){
          for(let i=0;i<this.vesselnamegroup.length;i++) {
            if (this.vesselnamegroup[i].value == this.searchTonform.shipVesselName) {
              this.selectvessel(this.vesselnamegroup[i])
              break
            }
          }
        }
      },
      //改变海域置空港口
      reselectsea(){
        if(this.seanamegroup.length != 0){
          for(let i=0;i<this.seanamegroup.length;i++) {
            if (this.seanamegroup[i].value == this.searchTonform.openSeaareaCname){
              this.selectopensea(this.seanamegroup[i])
              break
            }
          }
        }
      },
      reselectport(){
        if(this.portnamegroup.length != 0){
          for(let i=0;i<this.portnamegroup.length;i++) {
            if (this.portnamegroup[i].value == this.searchTonform.open_port){
              this.selectopenport(this.portnamegroup[i])
              break
            }
          }
        }
      },
      currentChange(n) {
        this.pagination.current = n
        this.searchtonnage()
      },
      vesselname(e){
        var value = e.data;
        if(value ==null)
          this.resetvesselname();
      },
      opensea(e){
        var value = e.data;
        if(value ==null)
          this.resetopensea();
      },
      openport(e){
        var value = e.data;
        if(value ==null)
          this.resetopenoprt();
      },
      resetvesselname(){
        this.searchTonform.shipId = "";
        this.searchTonform.shipVesselName = "";
      },
      resetopensea(){
        this.searchTonform.openSeaareaId = "";
        this.searchTonform.openSeaareaCname = "";
        this.searchTonform.openSeaareaEname = "";
      },
      resetopenoprt(){
        this.searchTonform.openPortId = "";
        this.searchTonform.openPortCname = "";
        this.searchTonform.openPortEname = "";
      },
      changeopenSeaareaName(value){
        if(value==''){
          this.searchTonform.openSeaareaId=''
        }else {
          this.searchTonform.openPortId = ''
          this.searchTonform.openPortEname = ''
          this.searchTonform.openPortCname = ''
          this.open_port = ''
          this.reselectsea()
        }
      },
      resetForm () {
        this.reload()
      }

    },
    created(){
      this.searchtonnage()
      this.buttonpermission = this.permission == null? localStorage.getItem('permission'):this.permission;
   //   console.log(this.buttonpermission)
      if(this.buttonpermission.includes('62005')){
        this.searchdisabled = false;
      }
      else this.searchdisabled = true;
      if(this.buttonpermission.includes('62006')){
        this.editdisabled = false;
      }
      else this.editdisabled = true;
      if(this.buttonpermission.includes('62007')){
        this.deletedisabled = false;
      }
      else this.deletedisabled = true;
      if(this.buttonpermission.includes('31000')){
        this.matchdisabled = false;
      }
      else this.matchdisabled = true;
    }
  }
</script>

<style lang="scss">
    .ss-tonnage{
        width:100%;
        heighT:100%;
        //background: pink;
        .search-ton{
            width:100%;
            heighT:100%;
            .ton-top{
                width:100%;
                /*height:26%;*/
                //background: blue;
                .box-card{
                    width:100%;
                    height:100%;
                    border:none;
                    .el-card__body{
                        width:100%;
                        height:100%;
                        padding:5px;
                        .el-form{
                            width:100%;
                            height:100%;
                            display: flex;
                            .form-left{
                                width:80%;
                                height:100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-around;
                                //background: pink;
                                .el-form-item{
                                    margin-bottom: 12px;
                                    #ship_years{
                                        margin-left: 20px;
                                    }
                                    .search_btn{
                                        width:30%
                                    }
                                    .el-button+.el-button{
                                        width:30%;
                                        margin-left: 30%;
                                    }
                                }
                                #col_2{
                                    color:#fff;
                                }
                            }
                            .form-right{
                                flex:1;
                                background: pink;
                            }
                        }
                    }
                }
            }
            .result_list{
                margin-top: 1%;
                width:100%;
                height:65%;
                position: relative;
                .el-tabs__header{
                    margin:0;
                }
                .el-tabs__content{
                    width:100%;
                    height:85%;
                    //background: brown;
                    overflow-y: auto;
                    #pane-card{
                        width:100%;
                        height:100%;
                        .list-area{
                            width:100%;
                            height:100%;
                            display: flex;
                            flex-wrap: wrap;
                            .list-item:hover{
                                cursor: pointer;
                            }
                            .list-item{
                                width:30%;
                                height: 220px;
                                margin-bottom: 15px;
                                margin-right: 2%;
                                // background: #409EeF;
                            }
                        }

                    }
                    #pane-table{
                        width:100%;
                        height:100%;
                        .el-table{
                            margin-bottom: 30px;
                            .actions{
                                display: flex;
                                justify-content: space-between;
                                .actions-box{
                                    flex:1;
                                    display: flex;
                                    justify-content: space-around;
                                    .el-button--mini{
                                        padding:3px 7px;
                                    }
                                }
                                .collect:hover{
                                    cursor: pointer;
                                }
                                .collect{
                                    width:28px;
                                    height:28px;
                                    margin-left: 10px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                        }
                    }
                }
            }
            .list-footer{
                width:100%;
                display: flex;
                justify-content: center;
                #tiao{
                    margin-right: 30px;
                }
            }
        }
        .search-ton-mobile{
            width:100%;
            heighT:100%;
            .ton-top{
                width:100%;
                height:46%;
                //background: blue;
                .box-card{
                    width:100%;
                    height: 100%;
                    .el-card__body{
                        width:100%;
                        height:100%;
                        padding:2px;
                        .el-form{
                            width:100%;
                            height:100%;
                            //display: flex;
                            #vessel_name{
                                margin-top: 1.5%;
                            }
                            .el-form-item{
                                margin-bottom: 10px;
                                #ship_years{
                                    margin-left: 20px;
                                }
                                .el-button+.el-button{
                                    margin-left: 25%;
                                }
                            }
                            #col_2{
                                color:#fff;
                            }
                        }
                    }
                }
            }
            h2{
                display: flex;
                justify-content: center;
                margin-top: 5%;
            }
            .list-area-mobile{
                width:96%;
                //height:90%;
                margin-left: 3.5%;
                display: flex;
                flex-wrap: wrap;
                //background: #aaa;
                overflow-y: hidden;
                .list-item:hover{
                    cursor: pointer;
                }
                .list-item{
                    width:100%;
                    margin-bottom: 15px;
                    margin-right: 5%;
                    // background: #409EeF;
                }
            }
            // .result_list{
            //     margin-top: 1%;
            //      width:100%;
            //      height:70%;
            //      .el-tabs__content{
            //          width:100%;
            //          height:85%;
            //          //background: brown;
            //          overflow-y: auto;
            //          #pane-card{
            //              width:100%;
            //              height:100%;
            //               .list-area{
            //                 width:100%;
            //                 height:100%;
            //                 display: flex;
            //                 flex-wrap: wrap;
            //                 .list-item:hover{
            //                     cursor: pointer;
            //                 }
            //                 .list-item{
            //                     width:30%;
            //                     //height:100px;
            //                     margin-bottom: 15px;
            //                     margin-right: 2%;
            //                 // background: #409EeF;
            //                 }
            //              }

            //          }
            //          #pane-table{
            //              width:100%;
            //              height:100%;
            //              .el-table{
            //                 margin-bottom: 30px;
            //                 .actions{
            //                     display: flex;
            //                     justify-content: space-between;
            //                     .collect:hover{
            //                         cursor: pointer;
            //                     }
            //                     .collect{
            //                         width:28px;
            //                         height:28px;
            //                         margin-left: 10px;
            //                         display: flex;
            //                         justify-content: center;
            //                         align-items: center;
            //                     }
            //                 }
            //             }
            //          }
            //      }
            // }
        }
    }
</style>
